<template>
  <div>
    <h2>Overview</h2>
    <p>
      The EverWatch Log Editor is a powerful content management tool that allows
      you to manage multiple log entries at once while keeping track of which
      log is being created or edited. Logs can be saved as drafts, published,
      and deleted individually, as well as all at once. Every log entry has a
      clickable header that opens and expands the log when clicked. Only one log
      can be opened at a time.
    </p>

    <v-img
      content-class="help-image-style"
      class="help-image"
      eager
      contain
      src="../../images/help/log_editor_header.png"
      transition="fade-transition"
      min-height="250px"
      max-height="250px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <h2>Managing Individual Log Entries</h2>
    <p>
      Each log entry has a rich text editor to complete the incident details and
      fields to fill out under the log header for each log. Additionally, there
      is a section for managing the parties involved in the log entry.
    </p>
    <p>
      After filling out the log information, there are three buttons at the end.
      Here's a brief description of each possible button action after you
      confirm it:
    </p>
    <ul>
      <li>
        [Save]: When clicked, this saves and publishes the current log. This
        makes the log available to all EW users.
      </li>
      <li>
        [Draft]: When clicked, the log is saved as a draft. This will
        temporarily save the log and allow you to edit it later using the white
        draft button on the EverWatch Logs Page. This does not make the log
        available to all EW users.
      </li>
      <li>
        [Delete]: When clicked, this removes the log entry from the editor.
      </li>
    </ul>
    <br />
    <p>
      If any of the above actions are performed while another log is still
      present in the editor, the log will be removed, and the next log will be
      selected automatically. Otherwise, the editor will automatically close.
    </p>

    <v-img
      class="help-image"
      content-class="help-image-style"
      eager
      contain
      src="../../images/help/log_editor_save.png"
      transition="fade-transition"
      min-height="100px"
      max-height="100px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <h2>Managing Multiple Log Entries</h2>
    <p>
      The footer of the Log Editor contains actions for managing multiple log
      entries. From left to right: left and right [arrow buttons] are available
      to quickly switch between the log entries, along with an indication of
      which log number is being viewed and how many logs are currently present
      in the Log Editor. This information is also available under each log
      header.
    </p>
    <p>
      An [add button] is located in the center of the Log Editor footer,
      allowing you to add a new log entry. This action is disabled during
      editing.
    </p>
    <p>
      Three buttons can be found on the right side of the footer. Here's a brief
      description of each possible button action after you confirm it:
    </p>
    <ul>
      <li>
        [Save all logs]: When this button is clicked, all logs in the Editor are
        saved and published. This makes all the logs available to all EW users.
      </li>
      <li>
        [Draft all logs]: When clicked, all logs will be saved as drafts. This
        will save all logs temporarily and allow you to edit them later by
        clicking the white draft button on the EverWatch Logs Page. This does
        not make the log accessible to all EW users.
      </li>
      <li>
        [Close]: When clicked, this closes the Log Editor. All unsaved changes
        will be lost.
      </li>
    </ul>

    <v-img
      class="help-image"
      content-class="help-image-style"
      eager
      contain
      src="../../images/help/log_editor_footer.png"
      transition="fade-transition"
      min-height="100px"
      max-height="100px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
/* eslint-disable */
export default {
  name: "log_editor_page_help",
  data() {
    return {};
  },
  methods: {
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
